/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Container, Divider, Inline, Modal, Typography,
} from '@airbus/components-react';
import './DisclaimerModalPopup.scss';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import { postDisclaimerStatus } from '../../models/disclaimerModal/disclaimerThunk';
import {
  DECLINE, DISCLAIMER_BODY_TEXT, DISCLAIMER_HEADER_TEXT, DISCLAIMER_REASON_TEXT, DISCLAIMER_TEXT, DISCLAIMER_WARNING_TEXT, DATA_WARNING_TEXT, PROCEED, USER_SCOPE_NSWC,
} from '../../utils/constants';
import {
  getConsentFlag, getLastConsentWithExpiration, isConsentValid, populateCheckerFleet, populateCheckerOperators, removeCheckerFilters, removeLastConsentTimestamp, setConsentFlag, setLastConsentTimestamp,
} from '../../utils/commonUtil';
import { loadCheckerFleetData, loadCheckerOperators } from '../../models/checkerFleetModel/checkerFleetThunk';
import { useAppSelector } from '../../store/hooksTypes';
import { setDisclaimerStatus } from '../../utils/commonMethods/generalMethods';

interface DisclaimerInterface {
  isOpen: boolean;
  setDisclaimerIsOpen: any;
  userIcao: string | undefined;
  dispatcher: any;
}
const DisclaimerModalPopup = ({
  isOpen, setDisclaimerIsOpen, userIcao, dispatcher,
}: DisclaimerInterface) => {
  const navigate = useNavigate();
  const [applyBtnDisabler, setapplyBtnDisabler] = useState(false);
  const checkerState = useAppSelector((state) => state.checkerFleet);
  const userPermissions = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  useEffect(() => {
    const fleetPayload: any = {
      conditions: {},
      operator_code_icao: checkerState.fleet_view_filter_selected?.selected_operator,
      orderBy: ['registration_number'],
      sortType: 'ASC',
    };
    if (!isEmpty(checkerState.fleet_view_filter_selected?.selected_operator) && isOpen) {
      populateCheckerFleet(dispatcher, loadCheckerFleetData, fleetPayload, setDisclaimerIsOpen, checkerState);
    }
  }, [checkerState.fleet_view_filter_selected?.selected_operator]);
  const updateDisclaimer = () => {
    setapplyBtnDisabler(true);
    // populate the operators based on consent validity
    const consentExpiration = getLastConsentWithExpiration();
    const consentFlag = getConsentFlag();
    if (isConsentValid(consentExpiration, consentFlag)) {
      populateCheckerOperators(dispatcher, loadCheckerOperators, checkerState);
    } else {
      const disclaimerUpdatePayload: DisclaimerStatusAPIPayloadType = {
        operator_code_icao: new Array(String(userIcao)),
      };
      removeCheckerFilters();
      removeLastConsentTimestamp();
      dispatcher(postDisclaimerStatus(disclaimerUpdatePayload)()).then(() => {
        setDisclaimerStatus();
        setDisclaimerIsOpen(false);
        // store consent date and consent flag
        setLastConsentTimestamp(new Date());
        setConsentFlag('true');
      });
    }
  };
  const cancelDisclaimer = () => {
    setConsentFlag('false');
    removeCheckerFilters();
    removeLastConsentTimestamp();
    navigate('/SignOut');
  };
  return (
    <Container>
      <Modal
        aria-labelledby="modal-title"
        className="disclaimer-popup-popup-container"
        open={isOpen}
      >
        <div className="modal-container">
          <Container className="disclaimer-container">
            <div className="header-section">
              <Typography variant="medium">{DISCLAIMER_HEADER_TEXT}</Typography>
            </div>
            <Divider />
            <Typography className="modal-content-text">{ DISCLAIMER_BODY_TEXT }</Typography>
            {featureToggleConfig?.nswc === 'true' && userPermissions.userScope === USER_SCOPE_NSWC
              ? (
                <Typography className="modal-content-text">{DATA_WARNING_TEXT}</Typography>
              )
              : (
                <Typography className="modal-content">
                  {DISCLAIMER_WARNING_TEXT}
                  <br />
                  {DISCLAIMER_REASON_TEXT}
                  <br />
                  {DISCLAIMER_TEXT}
                </Typography>
              )}
            <Divider />
            <Inline spacing="half-x" align="end" className="disclaimer-buttons">
              <Button size="small" data-testid="cancel-disclaimer" className="decline-button" onClick={cancelDisclaimer}>{DECLINE}</Button>
              <Button tabIndex={-3} size="small" data-testid="apply-disclaimer" disabled={applyBtnDisabler} className="apply-button" onClick={updateDisclaimer}>{PROCEED}</Button>
            </Inline>
          </Container>
        </div>
      </Modal>
    </Container>
  );
};
export default DisclaimerModalPopup;
