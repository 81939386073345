/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-plusplus */
import _ from 'lodash';
import { clearFleetDetailSelectedFin, setCommentsFinReducer, setFinChangesStateReducer } from '../../models/checkerDetailsModel/checkerDetailsSlice';
import { updateCheckerFinStatusDetails, loadCheckerDetails } from '../../models/checkerDetailsModel/checkerDetailsThunk';
import { addToast, removeToast } from '../../models/toastModel/toastSlice';
import {
  FIN_STATUS_MAPPER_FOR_API, DATA_SOURCE_COMPARISON_VALUE, FIN_UPDATE_SUCCESS, FIN_UPDATE_FAILURE, TOAST_TIMEOUT,
  DEFAULT_OFFSET,
} from '../../utils/constants';
import { columns as checkerDetailscolumns } from '../CheckerDetailsTable/CheckerDetailsTableStructure';
import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';

// Write back status submit handler
export const handleFinStatusSubmit = async ({
  checkerDetailFiltersSelected, checkerDataTableState, finChangesState, CheckerFinEditModalPopupDispatcher, isClearSelection,
}: any) => {
  let toastValForFinUpdatedRes: ToastType = {
    toastLocation: 'api/checker/update_fin_detail',
    isToastShown: true,
    toastMessage: '',
    toastVariantType: 'success',
    customToastIcon: '',
  };
  const writebackStatusPayload = {
    operator_code_icao: new Array(checkerDetailFiltersSelected.operator_name),
    data: [],
  };
  const finChangesArray = finChangesState.map((finVal: CheckerSelectedFinDataType) => {
    return {
      id_aircraft_registration: finVal.id_aircraft_registration,
      fleet_fin: finVal.fleet_fin,
      fin_status: FIN_STATUS_MAPPER_FOR_API[finVal.fin_status] ? FIN_STATUS_MAPPER_FOR_API[finVal.fin_status] : finVal.fin_status,
      comment: finVal.comment,
    };
  });
  writebackStatusPayload.data = finChangesArray;
  const response = await CheckerFinEditModalPopupDispatcher(updateCheckerFinStatusDetails(writebackStatusPayload)());
  if (!('error' in response)) {
    const payloadConditions = {
      id_aircraft_registration: checkerDataTableState.checkerDetailFiltersSelected.selected_msn,
      aircraft_type: checkerDataTableState.checkerDetailFiltersSelected.aircraft_type,
      ac_subtype: checkerDataTableState.checkerDetailFiltersSelected.aircraft_subtype,
      data_source: [DATA_SOURCE_COMPARISON_VALUE.PAYLOAD_VALUE],
      ata: checkerDataTableState.checkerDetailFiltersSelected.selected_ata,
      fleet_fin: checkerDataTableState.checkerDetailFiltersSelected.selected_fin,
    };
    const conditionsRes = Object.fromEntries(Object.entries(payloadConditions).filter(([, v]) => !_.isEmpty(v) && !(v.length === 1 && v[0] === '')));
    let payload = {
      operator_code_icao: new Array(checkerDetailFiltersSelected.operator_name),
      conditions: conditionsRes,
      distinct: true,
      columns: checkerDetailscolumns,
    };
    if (Object.keys(checkerDataTableState.FilterPayload).length > 0) { payload = checkerDataTableState.FilterPayload; }
    CheckerFinEditModalPopupDispatcher(loadCheckerDetails({
      ...payload,
      offset: Number(DEFAULT_OFFSET),
      limit: Number(getFromLocalStore('apiPaginationSize')),
    }, {})());
    // Show success toast if fin edited
    toastValForFinUpdatedRes = {
      ...toastValForFinUpdatedRes, toastMessage: FIN_UPDATE_SUCCESS, toastVariantType: 'success', isToastShown: true,
    };
    CheckerFinEditModalPopupDispatcher(addToast({
      ...toastValForFinUpdatedRes,
    }));
    CheckerFinEditModalPopupDispatcher(clearFleetDetailSelectedFin(!isClearSelection));
    CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer([]));
  } else {
    toastValForFinUpdatedRes = {
      ...toastValForFinUpdatedRes, toastVariantType: 'error', toastMessage: FIN_UPDATE_FAILURE, isToastShown: true,
    };
    // if failed to update fin status show toast
    CheckerFinEditModalPopupDispatcher(addToast({
      ...toastValForFinUpdatedRes,
    }));
  }
  setTimeout(() => {
    CheckerFinEditModalPopupDispatcher(
      removeToast({
        ...toastValForFinUpdatedRes,
      }),
    );
  }, TOAST_TIMEOUT);
};

// To get the previous history
export const getHistory = (historyRecords: any) => {
  const transition = [];
  for (let i = 0; i < historyRecords.length - 1; i++) {
    const currentStatus = historyRecords[i].fin_status;
    const Comments = historyRecords[i].comment;
    const timeStamp = historyRecords[i].revised_timestamp;
    const updatedBy = historyRecords[i].updated_by;
    const previousStatus = historyRecords[i + 1].fin_status;
    transition.push({
      previousStatus, currentStatus, updatedBy, timeStamp, Comments,
    });
  }
  return transition;
};

// To change the status in find edit
export const handleStatusChange = (value: string, {
  setSelectedStatusValue, checkerDataTableState, statusProps, finStatus, CheckerFinEditModalPopupDispatcher,
}: any) => {
  setSelectedStatusValue(value);
  const finalStatusData = checkerDataTableState.finChangesState.map((item: CheckerSelectedFinDataType) => {
    return { ...item };
  });
  const selectedIndex = finalStatusData.findIndex((finValue: CheckerSelectedFinDataType) => (finValue.uniqueid === statusProps.uniqueid));
  if (selectedIndex !== -1) {
    if (finStatus === value) {
      finalStatusData[selectedIndex].comment = '';
    }
    finalStatusData[selectedIndex].fin_status = value;
  }
  CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer(finalStatusData));
};

// To handle onchange of comments section
export const handleOnchangeComment = (value: any, index: number, commentFinState: any, selectedFinValues: CheckerSelectedFinDataType, {
  commentText, setCommentText, checkerDataTableState, CheckerFinEditModalPopupDispatcher,
}: any) => {
  // Update value in local state to display current value in UI
  const newFinArr = commentText.map((item: CheckerSelectedFinDataType, indexOfItem: number) => {
    if (indexOfItem === index) {
      return { ...item, comment: value };
    }
    return { ...item };
  });
  setCommentText(newFinArr);
  // Updated the value in state to track status changed fins
  const newFinArrState = checkerDataTableState.finChangesState.map((item: CheckerSelectedFinDataType) => {
    return { ...item };
  });
  const selectedIndex = newFinArrState.findIndex((finValue: CheckerSelectedFinDataType) => (finValue.uniqueid === selectedFinValues.uniqueid));
  if (selectedIndex !== -1) {
    newFinArrState[selectedIndex].comment = value;
  }
  CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer(newFinArrState));
  const updatedComments = [...commentFinState];
  updatedComments[index] = value;
  CheckerFinEditModalPopupDispatcher(setCommentsFinReducer({ commentList: updatedComments }));
};
