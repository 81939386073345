/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react-hooks/rules-of-hooks */
/* eslint-disable react-hooks/exhaustive-deps */
import { Container, Tab, Tabs } from '@airbus/components-react';
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './HeaderTab.scss';
import { setHelpModelToggle } from '../../../../models/helpModel/helpModelSlice';
import { useAppDispatch, useAppSelector } from '../../../../store/hooksTypes';
import { generateHeaderTabText, setTab } from '../../../../utils/commonMethods/generalMethods';
import { toggleReasonDetail } from '../../../../models/checkerDetailsModel/checkerDetailsSlice';
import { getUserPermissions } from '../../../../models/authModel/authEntryThunks';

interface HeaderTabs {
  id: number;
  title: string;
  routeTo: string;
  disabled: boolean;
}

const HeaderTab: React.FC = function HeaderTab() {
  const [selectedTab, setSelectedTab] = useState(1);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [headerTabText, setHeaderTabText] = useState<Array<HeaderTabs>>([]);
  const authEntry = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);

  useEffect(() => {
    dispatch(getUserPermissions()());
  }, []);

  useEffect(() => {
    let allowedRoutes = authEntry.moduleList;
    const exportAcm = featureToggleConfig?.exportAcm === 'true';
    if (!exportAcm) {
      allowedRoutes = allowedRoutes.filter((item: string) => item !== '/acm');
    }
    setHeaderTabText(generateHeaderTabText(allowedRoutes));
  }, [authEntry.moduleList, featureToggleConfig.exportAcm]);

  useEffect(() => {
    const currNav: any = headerTabText.filter((navItem) => location.pathname === navItem.routeTo || ((location.pathname === '/checker/details' || location.pathname === '/checker') && navItem.routeTo === '/'))[0];
    // For Concurent tab, selected module will always be the browser
    // for dev and val if checker exists then by default checker will be selected
    // else whatever comes in the middle of the list.
    setTab(location, setSelectedTab, authEntry, currNav);

    // close the reason detail popup if it is open and tab is switched
    dispatch(toggleReasonDetail(false));
  }, [location, selectedTab, toggleReasonDetail, authEntry.moduleList]);

  return (
    <Container until="xs" className="tab-container">
      <Tabs value={selectedTab} className="tab-select-container">
        {
        headerTabText.map((tab) => (
          <Tab
            key={tab.id}
            value={tab.id}
            onClick={() => {
              navigate(tab.routeTo, {
                state: location.pathname,
              });
              dispatch(getUserPermissions()());
              dispatch(setHelpModelToggle({ helpModalState: false, sourceOfHelpClick: '' }));
            }}
            size="large"
            aria-label="HeaderNav"
            className="selected-tab"
            disabled={tab.disabled}
          >
            {tab.title}
          </Tab>
        ))
      }
      </Tabs>
    </Container>
  );
};
export default HeaderTab;
