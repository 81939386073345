/* eslint-disable no-nested-ternary */
/* eslint-disable @typescript-eslint/no-explicit-any */

import React, { useEffect } from 'react';
import {
  ComboBox, Typography, Row, Col,
} from '@airbus/components-react';
import '../ModalPopup.scss';
import _ from 'lodash';
import {
  ASCENDING,
  COMBOBOX_PLACEHOLDER, DATA_SOURCE, DEFAULT_OFFSET, MAX_LIMIT,
} from '../../../../utils/constants';
import { loadFin, loadPnr } from '../../../../models/popupFilterModel/popupFilterThunks';
import { useAppDispatch } from '../../../../store/hooksTypes';
import { getMsnForPartBrowser } from '../../../../models/popupFilterModel/popupFilterSlice';
import { getAcSubTypeList, registerPostHogProperties } from '../../../../utils/commonUtil';

const DataSourceField = function DataSourceField(props: any) {
  const { DataSourceFieldProps } = props;
  const dispatch = useAppDispatch();
  const getPayload = (dataSource: string) => {
    const arrayOperator = new Array(DataSourceFieldProps.currentFilterConfig?.operator_name);
    const arrayACType = DataSourceFieldProps.currentFilterConfig?.aircraft_type;
    const arrayACSubType = DataSourceFieldProps.currentFilterConfig?.aircraft_subtype.length > 0
      ? DataSourceFieldProps.currentFilterConfig?.aircraft_subtype
      : getAcSubTypeList(arrayACType, DataSourceFieldProps.filterProps.aircraftSubType);
    const arrayDatasource = dataSource;
    const bodyPayload: any = {
      conditions: {
        aircraft_type: arrayACType,
        source: new Array(arrayDatasource),
        aircraft_model: arrayACSubType,
      },
      operator_code_icao: arrayOperator,
      data_source: arrayDatasource,
      limit: Number(MAX_LIMIT),
      offset: Number(DEFAULT_OFFSET),
      sortType: ASCENDING,
      distinct: true,
    };
    return bodyPayload;
  };
  const handleResetMSNAndDataSourceValues = async () => {
    const bodyPayload: any = getPayload(DataSourceFieldProps.filterProps?.selectedFilterState?.selected_dataSource);
    const msnPayload: any = {
      aircraft_types: bodyPayload.conditions.aircraft_type,
      operator_code_icao: DataSourceFieldProps.currentFilterConfig?.operator_name,
      part_data_selection_columns: DataSourceFieldProps.filterProps.part_data_selection_columns,
      aircraft_family: bodyPayload.conditions.aircraft_model,
      source: bodyPayload.data_source,
    };
    dispatch(getMsnForPartBrowser(msnPayload));
  };

  const handleDataSourceSelection = async (selectDataSource: any) => {
    registerPostHogProperties({
      DATA_SOURCE: selectDataSource,
    });
    DataSourceFieldProps.setCurrentFilterConfig((prevState: any) => {
      return {
        ...prevState,
        selected_dataSource: selectDataSource,
        selected_msn: [],
        selected_fin: [],
        selected_pnr: [],
      };
    });
    if (selectDataSource) {
      const bodyPayload: any = getPayload(selectDataSource);
      const msnPayload: any = {
        aircraft_types: bodyPayload.conditions.aircraft_type,
        operator_code_icao: DataSourceFieldProps.currentFilterConfig?.operator_name,
        part_data_selection_columns: DataSourceFieldProps.filterProps.part_data_selection_columns,
        aircraft_family: bodyPayload.conditions.aircraft_model,
        source: bodyPayload.data_source,
      };
      dispatch(getMsnForPartBrowser(msnPayload));
    }
  };
  // useEffect to load the fin and pnr whenever the change in the msn list options
  useEffect(() => {
    if (!_.isEmpty(DataSourceFieldProps.currentFilterConfig?.selected_dataSource) && DataSourceFieldProps.filterProps?.filterState.part_msns.length) {
      const bodyPayload: any = getPayload(DataSourceFieldProps.currentFilterConfig?.selected_dataSource);
      const bodyPayloadFinPnr = JSON.parse(JSON.stringify(bodyPayload));
      bodyPayloadFinPnr.data_source = DataSourceFieldProps.currentFilterConfig?.selected_dataSource;
      delete bodyPayloadFinPnr.conditions.source;
      delete bodyPayloadFinPnr.conditions.aircraft_type;
      delete bodyPayloadFinPnr.conditions.aircraft_model;
      bodyPayloadFinPnr.conditions.id_aircraft_registration = DataSourceFieldProps.filterProps?.filterState.part_msns;
      dispatch(loadFin({ ...bodyPayloadFinPnr, columns: ['fin'], orderBy: ['fin'] })());
      dispatch(loadPnr({ ...bodyPayloadFinPnr, columns: ['pnr'], orderBy: ['pnr'] })());
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DataSourceFieldProps.currentFilterConfig?.selected_dataSource]);
  useEffect(() => {
    // Resetting MSN values in not validate case
    if (!_.isEmpty(DataSourceFieldProps.filterProps?.selectedFilterState?.operator_name) && !_.isEmpty(DataSourceFieldProps.filterProps?.selectedFilterState?.selected_dataSource)) {
      handleResetMSNAndDataSourceValues();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Row>
      <Col xxs={4}>
        <Typography variant="small" className="filter-label">
          {DATA_SOURCE}
          <span className="required-fields">*</span>
        </Typography>
      </Col>
      <Col>
        <ComboBox
          aria-label="Select Data Source"
          placeholder={COMBOBOX_PLACEHOLDER}
          value={DataSourceFieldProps.currentFilterConfig?.selected_dataSource}
          data-testid="data-source"
          onChange={(value: any) => handleDataSourceSelection(value)}
          options={DataSourceFieldProps.filterProps.filterState.datasource}
          getOptionLabel={(option) => option}
          getOptionValue={(option) => option}
          disabled={!DataSourceFieldProps.currentFilterConfig?.aircraft_type.length}
          hideClear
        />
      </Col>
    </Row>
  );
};
export default DataSourceField;
