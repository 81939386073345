/* eslint-disable @typescript-eslint/no-explicit-any */
import { Chip } from '@airbus/components-react';
import { CheckCircleOutline, CancelOutline } from '@airbus/icons/react';
import { HeaderWithInfo, tableInCellRows } from '../../utils/TableUtils/ColumnUtils';
import {
  EDIT, FIN, FIN_EDIT_TOOLTIP, FIN_STATUS_MAPPER, INTERNAL_ABUS, READ_ONLY_ARR, STATUS_PART_CHECK_NA,
} from '../../utils/constants';
import { TooltipInfo } from '../CheckerDetails/TooltipInfo/TooltipInfo';
import { chipConstants } from '../Shared/ChipLabel/constants';
import './CheckerDetailsTable.scss';

export const checkerTableColumns = [
  {
    align: 'center',
    accessor: 'status_checkbox',
    id: 'status_checkbox',
    Header: HeaderWithInfo(EDIT, FIN_EDIT_TOOLTIP),
    Cell: (row: any) => tableInCellRows(row),
    width: 37,
    minWidth: 20,
    disableSortBy: true,
  },
  {
    align: 'left',
    accessor: 'fin',
    id: 'fleet_fin',
    Header: TooltipInfo(true, false),
    Cell: (row: any) => tableInCellRows(row),
    width: 40,
    minWidth: 40,
    disableSortBy: true,
  },
  {
    align: 'left',
    accessor: 'id_aircraft_registration',
    id: 'id_aircraft_registration',
    Header: 'MSN',
    Cell: (row: any) => tableInCellRows(row),
    width: 40,
    minWidth: 50,
    disableSortBy: true,
  },
  {
    align: 'left',
    accessor: 'ata',
    id: 'ata',
    Header: 'ATA',
    Cell: (row: any) => tableInCellRows(row),
    width: 40,
    minWidth: 40,
    disableSortBy: true,
  },
  {
    align: 'left',
    accessor: 'description',
    id: 'fin_description',
    Header: 'Description',
    Cell: (row: any) => tableInCellRows(row),
    width: 80,
    disableSortBy: true,
  },
  {
    align: 'left',
    accessor: 'source',
    id: 'source',
    Header: 'Source',
    Cell: (row: any) => tableInCellRows(row),
    width: 35,
    minWidth: 35,
    disableSortBy: true,
    disableSortByFilterOption: true,
  },
  {
    align: 'left',
    accessor: 'status_part_check',
    id: 'status_part_check',
    Header: TooltipInfo(false, true),
    Cell: tableInCellRows,
    width: 40,
    minWidth: 40,
    disableSortBy: true,
    disableSortByFilterOption: true,
  },
  {
    align: 'left',
    accessor: 'pnr',
    id: 'pnr',
    Header: 'Part Number',
    Cell: (row: any) => tableInCellRows(row),
    width: 80,
    disableSortBy: true,
    disableSortByFilterOption: true,
  },
  {
    align: 'left',
    accessor: 'source_status',
    id: 'source_status',
    Header: 'Status',
    Cell: (row: any) => tableInCellRows(row),
    width: 80,
    disableSortBy: true,
    disableSortByFilterOption: true,
  },
  {
    align: 'left',
    accessor: 'reason',
    id: 'reason',
    Header: 'Reason',
    Cell: (row: any) => tableInCellRows(row),
    width: 80,
    minWidth: 80,
    disableSortBy: true,
    disableSortByFilterOption: true,
  },
  {
    align: 'left',
    accessor: 'details',
    id: 'details',
    Header: 'More Info',
    Cell: (row: any) => tableInCellRows(row),
    width: 36,
    minWidth: 36,
    disableSortBy: true,
  },
];
export const checkerTableExtraColumns = [
  {
    align: 'left',
    accessor: 'fin_status',
    id: 'fin_status',
    Header: 'FIN Status',
    width: 40,
    minWidth: 40,
    disableSortBy: true,
  },
];

export const fleetColumns = [
  'operator_code_icao',
  'id_aircraft_registration',
  'fleet_fin',
  'ata',
  'fin_description',
  'fin_status',
  'status_part_check',
  'acd_date',
  'aisd_date',
  'allowable_config_date',
  'extraction_date',
  'user_id',
];

export const AcrColumns = [
  'acr_operator_code_icao',
  'acr_id_aircraft_registration',
  'acr_fin',
  'acr_pnr',
  'acr_source',
  'acr_as_allowed_pnr',
  'acr_as_allowed_cond',
  'acr_source_status',
  'acr_reason',
  'acr_applicability_status',
  'acr_reason_detail',
  'acr_reason_detail_status',
  'acr_org_fin',
  'acr_org_pnr',
  'acr_deviation_description',
  'acr_deviation_id',
  'acr_deviation_type',
];

export const MisColumns = [
  'mis_operator_code_icao',
  'mis_id_aircraft_registration',
  'mis_fin',
  'mis_pnr',
  'mis_source',
  'mis_as_allowed_pnr',
  'mis_as_allowed_cond',
  'mis_source_status',
  'mis_reason',
  'mis_applicability_status',
  'mis_reason_detail',
  'mis_reason_detail_status',
  'mis_madam_pnr',
  'mis_org_pnr',
  'mis_org_fin',
  'mis_deviation_description',
  'mis_deviation_id',
];

export const columns = [...fleetColumns, ...AcrColumns, ...MisColumns];

export const finCellValue: (fin: string, finStatus: string) => JSX.Element = (
  fin,
  finStatus,
) => {
  return (
    <div className="popup-fin-chip">
      <div className="popup-fin-val">{fin}</div>
      <div className="chip-container">
        <Chip
          data-testid="chip-label"
          label={chipConstants[FIN_STATUS_MAPPER[finStatus]]?.label}
          variant={chipConstants[FIN_STATUS_MAPPER[finStatus]]?.chipVariant}
          className={`custom-chips ${chipConstants[FIN_STATUS_MAPPER[finStatus]]?.className}`}
        />
      </div>
    </div>
  );
};

export const statusPartCheckValue: (value: string) => JSX.Element | string = (
  value,
) => {
  return value === 'NA' ? (
    <div className="partCheck-NA-style" data-testid="status-part-check">
      {STATUS_PART_CHECK_NA}
    </div>
  ) : (
    <div className="chip-container">
      <Chip
        data-testid="chipIcon"
        icon={
          chipConstants[value]?.customChipIcon === 'CheckCircleOutline' ? (
            <CheckCircleOutline className="check-icon" />
          ) : (
            <CancelOutline className="cross-icon" />
          )
        }
        className="chip-right"
      />
    </div>
  );
};

/* This function is for rearranging the Checker Details columns for Column level Filter.
We are separting Fin and Fin Status as separated dropdown option in Column Level filter */
export const getCheckerDetailsFinalFilterColumns: () => Array<any> = () => {
  const checkerDetailsFilterColumns = JSON.parse(JSON.stringify(checkerTableColumns.slice(1)));
  checkerDetailsFilterColumns.pop();
  checkerDetailsFilterColumns[0].Header = FIN;
  checkerDetailsFilterColumns[5].Header = 'Source Status';
  checkerDetailsFilterColumns.splice(1, 0, checkerTableExtraColumns[0]);
  return checkerDetailsFilterColumns;
};

/**
 *Instead of using table structure array directly, this function will be used.
 *Based on readonly or read write permission this will add or remove fin edit check box column
 * @returns Table structure Array for checker details
 */
export const getCheckerDetailsTableStructures: (permissionVal: string, userScope: string) => Array<any> = (permissionVal, userScope) => {
  if (INTERNAL_ABUS.includes(userScope) && READ_ONLY_ARR.includes(permissionVal)) {
    return checkerTableColumns.slice(1, checkerTableColumns.length);
  }
  return checkerTableColumns;
};
