/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable camelcase */
import { restructureResponseForCheckerTable } from '../../utils/commonMethods/generalMethods';
import { checkerTableColumns } from '../../components/CheckerDetailsTable/CheckerDetailsTableStructure';

export const CHECKER_DETAILS_SLICE = 'displayCheckerDetailsTable';
export const CHECKER_DETAILS_INITIAL_STATE: CheckerDetailsTableStateType = {
  isColumnDataLoading: false,
  isCheckerDetailsDataLoading: false,
  showReasonDetail: false,
  showFilterPanel: false,
  ifOperatorChanged: false,
  ifAircraftTypeChanged: false,
  ifAircraftSubTypeChanged: false,
  isFilterDetailsAtaLoading: false,
  isFilterDetailsFinLoading: false,
  isFinStatusUpdateLoading: false,
  paginationData: {
    rowPerPage: 100,
    page: 1,
    start: 0,
    end: 0,
    selectedChunkData: [],
  },
  total_records: 0,
  records_offset: 0,
  errorMessage: '',
  checkerDetailsData: restructureResponseForCheckerTable({
    ACR_MIS_data: [],
    acd_date: '',
    aisd_date: '',
    allowable_config_date: '',
    extraction_date: '',
    operator_code_icao: '',
    total_result: 0,
  }, {}),
  checkerDetailFiltersSelected: {
    operator_name: [],
    aircraft_type: [],
    selected_msn: [],
    selected_fin: [],
    aircraft_family: [],
    aircraft_subtype: [],
    selected_ata: [],
  },
  fleet_detail_filter_selected: {
    operator_name: [],
    aircraft_type: [],
    selected_msn: [],
    selected_fin: [],
    aircraft_family: [],
    aircraft_subtype: [],
    selected_ata: [],
  },
  fleetDetailFilterOptions: {
    operator_code_icao: [],
    ac_type: [],
    ac_sub_type: [],
    id_aircraft_registration: [],
    ac_family: [],
    ata: [],
    fin: [],
  },
  checkerDetailsFilterOptions: {
    operator_code_icao: [],
    ac_type: [],
    ac_sub_type: [],
    id_aircraft_registration: [],
    ac_family: [],
    ata: [],
    fin: [],
  },
  result: {
    ACR_MIS_data: [],
    acd_date: '',
    aisd_date: '',
    allowable_config_date: '',
    extraction_date: '',
    operator_code_icao: '',
    total_result: 0,
  },
  pageNumber: 1,
  rowPerPage: 10,
  checkerDetailsInitialLoad: true,
  columns: checkerTableColumns.filter((column: any) => column.hidden === false),
  FilterState: [],
  FilterPayload: [],
  FilterData: [],
  selectedFin: [],
  isBottomDrawerClose: false,
  isSelectAllFin: false,
  finEditModalState: false,
  finChangesState: [],
  isClearSelection: false,
  commentList: [],
  currentPopupDetails: {},
};

export const lOAD_CHECKER_DETAILS = 'loadCheckerDetailsTableData';
export const lOAD_CHECKER_FLEET_ATA_DETAILS = 'loadCheckerFleetAtaDetails';
export const lOAD_CHECKER_FLEET_FIN_DETAILS = 'loadCheckerFleetFinDetails';
export const lOAD_CHECKER_FLEET_FIN_ATA_DETAILS = 'loadCheckerFleetFinAndAtaDetails';
export const LOAD_CHECKER_FIN_HISTORY = 'loadCheckerFinHistory';
export const LOAD_FILTER_DATA = 'loadCheckerFilterData';
export const CHECKER_DATA_ERROR = 'Unable to get Checker Details Data';
export const UPDATE_CHECKER_FIN_STATUS = 'updateCheckerFinStatus';
export const FIN_HISTORY_EROR = 'Failed to get the fin history';
export const UPDATE_FIN_STATUS_EROR = 'Failed to update the fin status';
export const LOAD_REPORT_STATUS_CHART_ACR = 'loadReportStatusChartAcr';
export const LOAD_REPORT_STATUS_CHART_MIS = 'loadReportStatusChartMis';
export const LOAD_REPORT_STATUS_CHART_FIN_STATUS = 'loadReportStatusChartFinStatus';
export const LOAD_REPORT_STATUS_CHART_PNR_SOURCE = 'loadReportStatusChartPnrSource';
