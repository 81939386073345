/* eslint-disable camelcase */
/* CMI-S UI */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from 'react';
import { AddCircleOutline } from '@airbus/icons/react';
import {
  Checkbox,
  Chip,
  Col,
  Container, IconButton, Row, Spinner, Typography,
} from '@airbus/components-react';
import './CheckerDetailsTable.scss';
import { useAppSelector, useAppDispatch } from '../../store/hooksTypes';
import Table from '../Shared/Table/Table';
import {
  finCellValue, statusPartCheckValue, getCheckerDetailsFinalFilterColumns, getCheckerDetailsTableStructures,
} from './CheckerDetailsTableStructure';
import {
  CHECKER, DATA_WARNING_TEXT, INTERNAL_ABUS, NO_TABLE_RECORDS_FOUND, READ_ONLY_ARR, REASON_DETAIL_INITIAL_STATE,
  USER_SCOPE_NSWC,
} from '../../utils/constants';
import { handleCheckerDetailsPageChange } from '../../utils/commonUtil';
import { loadCheckerDetails, loadCheckerDetailsFilterData } from '../../models/checkerDetailsModel/checkerDetailsThunk';
import {
  setCurrentPopupDetails,
  setFinBottomDrawerStatus,
  setFleetDetailSelectedFin,
  toggleReasonDetail, updateCheckerDetailsColumnFilterPayload, updateCheckerDetailsColumnFilterState, updateCheckerDetailsDataPagination, updateSelectAllBottomDrawerStatus,
} from '../../models/checkerDetailsModel/checkerDetailsSlice';
import DetailsPopup from '../CheckerDetails/DetailsPopup/DetailsPopup';
import { getDataFromCheckerDetailsChild, handleCheckerDetailsFilterColumnPayload } from '../Shared/ColumnLevelModal/Utils/ColumnLevelhandleUtils';
import CheckerFinEditDrawer from '../CheckerFinEditDrawer/CheckerFinEditDrawer';
import CheckerFinEditModalPopup from '../CheckerFinEditComponent/CheckerFinEditModalPopup';
import { getPermissionForModule } from '../../utils/commonMethods/generalMethods';
import { setReasonTab } from '../../models/appConfigModel/appConfigSlice';

interface ReasonDetail {
  FIN?: any;
  MSN?: string;
  ATA?: string;
  Description?: string;
  PopupTableData: Array<any>;
  DeviationDetails: Array<any>;
  finValue?: string;
  operator_code_icao?: string;
  PNR?: any;
}

interface CheckerTableProps {
  exportType: string;
  paramString: any;
}
const CheckerDetailsTable: React.FC<CheckerTableProps> = function LoadingWrapper(props: CheckerTableProps) {
  const { exportType, paramString } = props;
  const [tableData, setTableData] = useState<Array<any>>([]);
  const [reasonDetailsData, setReasonDetailsData] = useState<ReasonDetail>(REASON_DETAIL_INITIAL_STATE);
  const [permissionValue, setPermissionValue] = useState<string>('');
  const { isCheckerDetailsDataLoading, paginationData, checkerDetailFiltersSelected } = useAppSelector(
    (state) => state.displayCheckerDetailsTable,
  );
  const { isDataLoading } = useAppSelector((state) => state.checkerFleet);
  const userPermissions = useAppSelector((state) => state.authEntrySlice);

  const [selectedFinData, setSelectedFinData] = useState<Array<CheckerSelectedFinDataType>>([]);
  const [checkboxChange, setCheckboxChange] = useState<CheckerSelectedFinDataType>({});
  const checkerDataTableState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const reasonStatusMapping = useAppSelector((state) => state.reasonStatusMapping);
  const { permissionObj, userScope } = useAppSelector((state) => state.authEntrySlice);
  const featureToggleConfig = useAppSelector((state) => state.featureToggleDetails?.data);
  const checkerDetailsDispatcher = useAppDispatch();
  const pagination = {
    sourceTab: CHECKER,
    tableState: checkerDataTableState,
    dispatcher: checkerDetailsDispatcher,
    handlePageChange: handleCheckerDetailsPageChange,
    updateTableDataReducer: loadCheckerDetails,
    updatePaginationReducer: updateCheckerDetailsDataPagination,
  };

  useEffect(() => {
    if ('eve' in checkboxChange) {
      if (checkboxChange.eve === true) {
        setSelectedFinData(
          [...selectedFinData,
            {
              fleet_fin: checkboxChange.fleet_fin,
              fin_status: checkboxChange.fin_status,
              id_aircraft_registration: checkboxChange.id_aircraft_registration,
              ata: checkboxChange.ata,
              part_number: checkboxChange.part_number,
              user_id: checkboxChange.user_id,
              uniqueid: checkboxChange.uniqueid,
            }],
        );
      } else {
        const newFinArr = [...selectedFinData];
        const selectedIndex = newFinArr.findIndex((value: CheckerSelectedFinDataType) => (value.uniqueid === checkboxChange?.uniqueid));
        newFinArr.splice(selectedIndex, 1);
        setSelectedFinData(newFinArr);
      }
    }
  }, [checkboxChange]);

  useEffect(() => {
    if (selectedFinData !== checkerDataTableState.selectedFin) checkerDetailsDispatcher(setFleetDetailSelectedFin(selectedFinData));
  }, [selectedFinData]);

  useEffect(() => {
    const isOpenDrawer = !!checkerDataTableState.selectedFin.length;
    // Need to set local fin data when directly state changed
    if (checkerDataTableState.selectedFin !== selectedFinData) setSelectedFinData([...checkerDataTableState.selectedFin]);
    // Update select All checkbox based on rows selection
    if ((checkerDataTableState.isSelectAllFin && checkerDataTableState.selectedFin.length !== tableData.length)
      || (tableData.length && !checkerDataTableState.isSelectAllFin && checkerDataTableState.selectedFin.length === tableData.length)) {
      checkerDetailsDispatcher(updateSelectAllBottomDrawerStatus(!checkerDataTableState.isSelectAllFin));
    }
    checkerDetailsDispatcher(setFinBottomDrawerStatus(isOpenDrawer));
    setCheckboxChange({});
  }, [checkerDataTableState.selectedFin]);

  useEffect(() => {
    // While clicking select All, pushing all table rows into selected fin array
    if (checkerDataTableState.isSelectAllFin) {
      const data = paginationData.selectedChunkData.map((item: CheckerTableDataType, index: number) => {
        return {
          fleet_fin: item.fleet_fin,
          fin_status: item.fin_status,
          id_aircraft_registration: item.id_aircraft_registration,
          ata: item.ata,
          part_number: item.pnr,
          user_id: item.user_id,
          uniqueid: index + item.fleet_fin,
        };
      });
      checkerDetailsDispatcher(setFleetDetailSelectedFin(data));
    }
  }, [checkerDataTableState.isSelectAllFin]);

  // to clear selected checboxes while making changes in top filters or data selection
  useEffect(() => {
    setSelectedFinData([]);
    checkerDetailsDispatcher(updateSelectAllBottomDrawerStatus(false));
  }, [checkerDataTableState.isClearSelection]);

  useEffect(() => {
    const data = paginationData.selectedChunkData.map((item: CheckerTableDataType, index: number) => {
      const dataObj: any = {
        status_checkbox: (
          <Row>
            <Col>
              <Checkbox
                data-testid="select-row-checkbox"
                className="status-editCheckbox"
                checked={checkerDataTableState.selectedFin?.findIndex(
                  (value: CheckerSelectedFinDataType) => (value.uniqueid === (index + item.fleet_fin)),
                ) !== -1}
                onChange={(event) => {
                  setCheckboxChange({
                    fleet_fin: item.fleet_fin,
                    fin_status: item.fin_status,
                    eve: event.target.checked,
                    id_aircraft_registration: item.id_aircraft_registration,
                    ata: item.ata,
                    part_number: item.pnr,
                    user_id: item.user_id,
                    uniqueid: index + item.fleet_fin,
                  });
                }}
              />
              {item.user_id ? <Chip data-testid="editfin-chip" className="edited-chip" label="Edited" size="xsmall" /> : ''}
            </Col>
          </Row>),
        fin: finCellValue(item.fleet_fin, item.fin_status),
        id_aircraft_registration: item.id_aircraft_registration,
        ata: item.ata,
        description: item.fin_description,
        source: item.source,
        status_part_check: statusPartCheckValue(item.status_part_check),
        pnr: item.pnr,
        as_allowed_pnr: item.as_allowed_pnr,
        as_allowed_cond: item.as_allowed_cond,
        source_status: item.source_status,
        reason: item.reason,
        details: (
          <div>
            <div className="details-icon">
              <IconButton
                data-testid="reason-details-icon"
                variant="ghost"
                aria-label="circle-outline"
                onClick={() => {
                  setReasonDetailsData({
                    FIN: finCellValue(item.fleet_fin, item.fin_status),
                    MSN: item.id_aircraft_registration,
                    ATA: item.ata,
                    Description: item.fin_description,
                    PopupTableData: item.popup_details,
                    DeviationDetails: item.deviation_details,
                    finValue: item.fleet_fin,
                    operator_code_icao: checkerDetailFiltersSelected.operator_name,
                    PNR: item.pnr,
                  });
                  checkerDetailsDispatcher(setReasonTab({ selectedReasonTab: 0 }));
                  checkerDetailsDispatcher(setCurrentPopupDetails({
                    fin: item.fleet_fin,
                    pnr: item.pnr,
                    msn: item.id_aircraft_registration,
                    ata: item.ata,
                    operator_code_icao: checkerDetailFiltersSelected.operator_name,
                  }));
                  checkerDetailsDispatcher(toggleReasonDetail(true));
                }}
              >
                <AddCircleOutline />
              </IconButton>
            </div>
          </div>),
        uniqueid: index + item.fleet_fin,
      };
      // get module name from URL
      const moduleName = window.location.pathname;
      const permissionVal = getPermissionForModule(moduleName, checkerDetailFiltersSelected.operator_name, userScope, permissionObj);
      setPermissionValue(permissionVal);
      // in case of readonly permission remove edit boxes
      if (INTERNAL_ABUS.includes(userScope)
        && READ_ONLY_ARR.includes(permissionVal)
        && Object.prototype.hasOwnProperty.call(dataObj, 'status_checkbox')) {
        delete dataObj.status_checkbox;
      }
      return dataObj;
    });

    // layer to process reason details data.
    setTableData(data);
  }, [paginationData.selectedChunkData, checkerDataTableState.selectedFin, permissionObj, userScope]);
  const checkerDetailsFilterColumns = getCheckerDetailsFinalFilterColumns();
  const filterProps = {
    tableState: checkerDataTableState,
    popupFilterState: checkerDataTableState,
    tableStateColumns: checkerDetailsFilterColumns,
    dispatcher: checkerDetailsDispatcher,
    browserThunk: loadCheckerDetails,
    filterThunk: loadCheckerDetailsFilterData,
    filterPayloadFn: handleCheckerDetailsFilterColumnPayload,
    getDataPayloadFn: getDataFromCheckerDetailsChild,
    updateColumnFilterState: updateCheckerDetailsColumnFilterState,
    updateColumnFilterPayload: updateCheckerDetailsColumnFilterPayload,
    reasonStatusMap: reasonStatusMapping,
  };
  return (
    <div>
      {paginationData.selectedChunkData.length > 0 ? (
        <Container className="checker-details-container">
          {featureToggleConfig?.nswc === 'true' && userPermissions.userScope === USER_SCOPE_NSWC
                  && (
                  <Typography className="data-warning-text" variant="small" data-testid="checker-warning-data">
                    {DATA_WARNING_TEXT}
                  </Typography>
                  )}
          <Table
            data-testid="checker-data-table"
            tableData={tableData}
            headerColumns={getCheckerDetailsTableStructures(permissionValue, userScope)}
            loadingupdate={isCheckerDetailsDataLoading}
            showChangeViewButton={false}
            showExportButton={featureToggleConfig?.exportChecker === 'true'}
            showFilterButton
            filterProps={filterProps}
            tableClassName={checkerDataTableState.selectedFin.length ? 'checker-data-table-fin-edit' : 'checker-data-table'}
            showTimeInfo
            showPagination
            paginationProps={pagination}
            selectedExport={checkerDataTableState.checkerDetailFiltersSelected}
            exportType={exportType}
            disableToolbarComponent={checkerDataTableState.selectedFin.length > 0}
            showReportToggleStatus
            paramString={paramString}
          />
          <DetailsPopup
            popupData={reasonDetailsData}
          />
          <CheckerFinEditDrawer />
          <CheckerFinEditModalPopup />
        </Container>
      ) : (
        <Container className="checker-no-data">
          {paginationData.selectedChunkData.length === 0 && isCheckerDetailsDataLoading === false && isDataLoading === false ? (
            <Typography className="no-data-text" data-testid="no-checker-data">
              {NO_TABLE_RECORDS_FOUND}
            </Typography>
          ) : (
            <Spinner size="small" className="checker-spinner" />
          )}
        </Container>
      )}
    </div>
  );
};
export default CheckerDetailsTable;
