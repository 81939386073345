/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button, Col, Container, Inline, Modal, Row, Spinner, Typography,
} from '@airbus/components-react';
import './CheckerFinEditComponent.scss';
import { Close } from '@airbus/icons/react';
import { useEffect, useState } from 'react';
import { useAppDispatch, useAppSelector } from '../../store/hooksTypes';
import {
  DESC_SORT_TYPE,
  CANCEL,
  UPDATE_RESULTS,
  MANAGE_FIN_STATUS_DESCRIPTION,
  SAVE,
  FIN_HISTORY_PAYLOAD_DEFAULT_COLUMNS,
  FIN_HISTORY_PAYLOAD_DEFAULT_ORDERBY,
} from '../../utils/constants';
import {
  clearFleetDetailSelectedFin, setCommentsFinReducer, setFinChangesStateReducer, setFinEditModalState,
} from '../../models/checkerDetailsModel/checkerDetailsSlice';
import { loadCheckerFinHistoryDetails } from '../../models/checkerDetailsModel/checkerDetailsThunk';
import CheckerFinInlineModelComponent from './CheckerFinInlineModelComponent';
import { handleFinStatusSubmit } from './CheckerFinEditModelUtils';
import { getFromLocalStore } from '../../utils/commonMethods/generalMethods';

const CheckerFinEditModalPopup = () => {
  const CheckerFinEditModalPopupDispatcher = useAppDispatch();
  const checkerDataTableState = useAppSelector((state) => state.displayCheckerDetailsTable);
  const {
    selectedFin, finEditModalState, checkerDetailFiltersSelected,
    finStatusHistory, finChangesState, isFinStatusUpdateLoading, isClearSelection, paginationData, commentList,
  } = checkerDataTableState;
  const [isError, setIsError] = useState<boolean>(false);
  useEffect(() => {
    const isLoadHistory = selectedFin.some((item: any) => item.user_id);
    if (finEditModalState && isLoadHistory) {
      const loadFinHistoryPayload = {
        operator_code_icao: new Array(checkerDetailFiltersSelected.operator_name),
        conditions: {
          data: selectedFin.map((item: any) => ({ id_aircraft_registration: item.id_aircraft_registration, fleet_fin: item.fleet_fin })),
        },
        columns: FIN_HISTORY_PAYLOAD_DEFAULT_COLUMNS,
        orderBy: FIN_HISTORY_PAYLOAD_DEFAULT_ORDERBY,
        limit: Number(getFromLocalStore('apiPaginationSize')),
        sortType: DESC_SORT_TYPE,
      };
      CheckerFinEditModalPopupDispatcher(loadCheckerFinHistoryDetails(loadFinHistoryPayload)());
    }
  }, [finEditModalState]);
  const handlerSubmitArgs = {
    checkerDetailFiltersSelected, checkerDataTableState, finChangesState, CheckerFinEditModalPopupDispatcher, paginationData, isClearSelection, commentList, setIsError,
  };
  const commentCount = finChangesState.length - ([...commentList as []].filter((i) => !!i).length || 0);
  const handleCancel = () => {
    CheckerFinEditModalPopupDispatcher(setFinEditModalState(!finEditModalState));
    CheckerFinEditModalPopupDispatcher(clearFleetDetailSelectedFin(!isClearSelection));
    CheckerFinEditModalPopupDispatcher(setFinChangesStateReducer([]));
    CheckerFinEditModalPopupDispatcher(setCommentsFinReducer({ commentList: [] }));
    setIsError(false);
  };
  return (
    <Container>
      <Modal
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
        className="fin-edit-modal-popup-container"
        id="fin-edit-detail-popup"
        open={finEditModalState}
      >
        <div className="modal-container">
          {isFinStatusUpdateLoading ? <Spinner size="medium" className="fin-edit-spinner" /> : <Typography />}
          <Container className={isFinStatusUpdateLoading ? 'fin-edit-disable-container' : 'fin-edit-container'}>
            <div className="header-section">
              <Row className="header-label">
                <Col xxs={6} className="header-title">
                  <Inline spacing="1-x">
                    <Typography variant="medium">{UPDATE_RESULTS}</Typography>
                  </Inline>
                </Col>
                <Col xxs={1}>
                  <Close
                    className="modal-close-icon"
                    data-testid="modal-close"
                    onClick={() => handleCancel()}
                  />
                </Col>
              </Row>
              <Row className="header-label">
                <Col>
                  <Typography variant="medium" className="header-label-description">
                    {MANAGE_FIN_STATUS_DESCRIPTION}
                  </Typography>
                </Col>
              </Row>
            </div>
            <CheckerFinInlineModelComponent
              selectedFin={selectedFin}
              finStatusHistory={finStatusHistory}
              checkerDataTableState={checkerDataTableState}
              CheckerFinEditModalPopupDispatcher={CheckerFinEditModalPopupDispatcher}
              isError={isError}
              commentCount={commentCount}
            />
            <Inline spacing="half-x" align="end" className="inline-button dataSelectionPrimary">
              {isError && commentCount > 0 && <Typography className="error-message" variant="small" data-testid="error-id">{`${commentCount} Mandatory fields missing`}</Typography>}
              <Button
                size="medium"
                variant="secondary"
                className="reset-button"
                onClick={() => handleCancel()}
                data-testid="modal-cancel"
              >
                {CANCEL}
              </Button>
              <Button
                size="medium"
                className="save-button"
                data-testid="modal-validate"
                onClick={() => {
                  if (commentCount === 0) {
                    setIsError(false);
                    handleFinStatusSubmit(handlerSubmitArgs);
                  } else {
                    setIsError(true);
                  }
                }}
              >
                {SAVE}
              </Button>
            </Inline>
          </Container>
        </div>
      </Modal>
    </Container>
  );
};
export default CheckerFinEditModalPopup;
