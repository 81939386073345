/* eslint-disable @typescript-eslint/no-explicit-any */
import { isEmpty } from 'lodash';
import BrowserTab from '../../components/Shared/Tabs/BrowserTab/BrowserTab';
import ConcurrentRequirement from '../../components/ConcurrentRequirement/ConcurrentRequirement';
import AcmTab from '../../components/Shared/Tabs/AcmTab/AcmTab';
import Checker from '../../components/Shared/Tabs/Checker/Checker';
import NoPermission from '../../components/Shared/NoPermission/NoPermission';
import CheckerTabs from '../../components/Shared/Tabs/Checker/CheckerTab/CheckerTabs';

export type RoutesConfig = {
    path: string;
    element: JSX.Element;
};
interface RouteElementMap {
  [key: string]: JSX.Element,
}

// create routes dynamically based on user permissions
export const createRouteMapping = (allowedRoutes: Array<string>, featureToggleConfig: any) => {
  let acmRoute = allowedRoutes;
  const exportAcm = featureToggleConfig?.exportAcm === 'true';
  const routeElementMap:RouteElementMap = {
    '/browser': <BrowserTab />,
    '/checker': <Checker />,
  };

  if (exportAcm) {
    routeElementMap['/acm'] = <AcmTab />;
  } else {
    acmRoute = acmRoute.filter((item: string) => item !== '/acm');
  }
  const mappedRoutes = acmRoute.map((key) => {
    return {
      path: key,
      element: routeElementMap[key],
    };
  });

  if (isEmpty(allowedRoutes)) {
    mappedRoutes.push({ path: '/', element: <NoPermission /> });
  } else {
    if (allowedRoutes.includes('/checker')) {
      mappedRoutes.push({ path: '/', element: <Checker /> });
      mappedRoutes.push({ path: '/checker/details', element: <CheckerTabs /> });
    } else {
      mappedRoutes.push({ path: '/', element: routeElementMap[allowedRoutes[0]] });
    }
    if (allowedRoutes.includes('/browser')) {
      mappedRoutes.push({ path: '/concurrentRequirement', element: <ConcurrentRequirement /> });
    }
  }
  return mappedRoutes;
};
