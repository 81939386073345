export const historyTableColumns = [
  {
    accessor: 'ingested_filename',
    id: 'ingested_filename',
    align: 'left',
    Header: 'File name',
    width: 1,
    disableSortBy: true,
  },
  {
    accessor: 'uploaded_date',
    id: 'uploaded_date',
    align: 'left',
    Header: 'Upload date',
    width: 60,
    disableSortBy: true,
  },
  {
    accessor: 'status',
    id: 'status',
    align: 'left',
    Header: 'FLI/Configuration Cockpit status',
    width: 80,
    disableSortBy: true,
  },
];
