/* eslint-disable no-plusplus */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Buffer } from 'buffer';
import _ from 'lodash';
import React, { useState, useEffect } from 'react';
import {
  Button, Container, Inline, ComboBox, Modal, Typography, Row, Col, Radio, Badge,
} from '@airbus/components-react';
import './ColumnLevelModal.scss';
import { Close, Tune } from '@airbus/icons/react';
import { useSearchParams } from 'react-router-dom';
import {
  FILTER, DATA_FILTER_TITLE, FILTER_BY, SORT_BY, APPLY, CLEAR, COMBOBOX_PLACEHOLDER, NODATA_FILTER,
} from '../../../utils/constants';
import { useAppSelector } from '../../../store/hooksTypes';
import ComboboxUtils from './Utils/ComboboxUtils';
import { filterStateCount, handleApplyFilter } from './Utils/ColumnLevelhandleUtils';
import { columnFilterStateUpdater, getSortByOptions } from '../../../utils/commonUtil';
import { columns as checkerDetailscolumns } from '../../CheckerDetailsTable/CheckerDetailsTableStructure';
import { getFromLocalStore } from '../../../utils/commonMethods/generalMethods';
import { toggleFilterModel } from '../../../models/checkerDetailsModel/checkerDetailsSlice';

interface ColumFilterProps {
  tableProps: any;
}
const ColumnLevelModal: React.FC<ColumFilterProps> = (props: ColumFilterProps) => {
  const { tableProps } = props;
  const {
    tableStateColumns, tableState, popupFilterState, dispatcher, filterThunk, browserThunk, filterPayloadFn, getDataPayloadFn, updateColumnFilterPayload, updateColumnFilterState, reasonStatusMap,
  } = tableProps;
  const columnLevelFilterState = tableState;
  const tableHeaders = tableStateColumns;
  let orderBy = '';
  let sortBy: any = null;
  if (columnLevelFilterState.FilterPayload?.orderBy) {
    orderBy = columnLevelFilterState.FilterPayload?.orderBy;
    const orderByHeader = tableHeaders.find((x: any) => x.id === orderBy[0]);
    if (orderByHeader && columnLevelFilterState.FilterPayload?.sortType) {
      sortBy = { label: orderByHeader.Header, value: columnLevelFilterState.FilterPayload?.orderBy[0] };
    } else if ('isCheckerDetailsDataLoading' in columnLevelFilterState && columnLevelFilterState.FilterPayload?.orderBy) {
      const sortByValue = orderBy[0].split('acr_')[1];
      const orderHeader = tableHeaders.find((x: any) => x.id === sortByValue);
      if (orderHeader) { sortBy = { label: orderHeader.Header, value: sortByValue }; }
    }
  }
  const orderByType = columnLevelFilterState.FilterPayload?.sortType === 'ASC' ? { option1: true, option2: false } : { option1: false, option2: true };
  const showFilterPanel = useAppSelector((state) => state.displayCheckerDetailsTable.showFilterPanel);
  const [showNoData, setShowNoData] = useState(false);
  const [options, setOptions] = useState(orderByType);
  const [resetForm, setResetForm] = useState(false);
  const [selectSortByValue, setSelectSortByValue] = useState(sortBy);
  const [dataFilterConfig, setdataFilterConfig] = useState<any>({});
  const isDataSelectionModalOpen = useAppSelector((state) => state.popUpFilter.modalToggle);
  const browserState = useAppSelector((state) => state.app);
  const [btnState, setBtnState] = useState(false);
  const chipCount = filterStateCount(dataFilterConfig.state, selectSortByValue);
  const stateCount = filterStateCount(columnLevelFilterState.FilterState, 0);
  const [qryParams] = useSearchParams();
  const qryParamsSet = qryParams.getAll('msn_sb');
  const qryParamsSetDecrypted = qryParamsSet && qryParamsSet.length ? JSON.parse(Buffer.from(qryParamsSet[0], 'base64').toString('ascii')) : '';
  // To handle apply button status to enable and disable
  const getBtnState = () => {
    // Handled deselection of the filter and selection as already exists
    const buttonState = (chipCount === 0
      && (!_.isEmpty(columnLevelFilterState.FilterState)
        && !_.isEmpty(dataFilterConfig.state)
        && JSON.stringify(columnLevelFilterState.FilterState) !== JSON.stringify(dataFilterConfig.state)))
      || chipCount > 0;
    setBtnState(buttonState);
  };

  useEffect(() => {
    getBtnState();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chipCount]);

  /* Open combo form event */
  const handleOpen = () => {
    dispatcher(toggleFilterModel(true));
    setSelectSortByValue(sortBy);
    getBtnState();
  };

  useEffect(() => {
    if (selectSortByValue) {
      setdataFilterConfig({
        ...dataFilterConfig,
        payload: {
          ...dataFilterConfig.payload, orderBy: new Array(selectSortByValue?.value), sortType: options.option1 ? 'ASC' : 'DESC',
        },
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options]);

  /* Sort By selection event function */
  const handleSortBySelection = (selectedValue: any) => {
    setSelectSortByValue(selectedValue);
    if (selectedValue) {
      setdataFilterConfig({
        ...dataFilterConfig,
        payload: {
          ...dataFilterConfig.payload, orderBy: new Array(selectedValue.value), sortType: options.option1 ? 'ASC' : 'DESC',
        },
      });
    }
  };
  const setDataConfig = (payloadArray: any, combo: any) => {
    setdataFilterConfig({ payload: payloadArray, state: combo });
    setResetForm(false);
  };
  /* Close combo form by event  */
  const handleClose = () => {
    dispatcher(toggleFilterModel(false));
    setdataFilterConfig({});
    setResetForm(false);
    setShowNoData(false);
  };

  /* Clear selected values by event  */
  const handleClearFilter = () => {
    setResetForm(true);
    setShowNoData(false);
    setSelectSortByValue(null);
    setdataFilterConfig({});
    const filterDispatchParam: any = [];
    columnFilterStateUpdater(dispatcher, filterDispatchParam, filterDispatchParam, updateColumnFilterState, updateColumnFilterPayload, filterDispatchParam);
    const intialStateFilter: any = {};
    tableHeaders.map((col: any) => {
      intialStateFilter[col.id] = [];
      return intialStateFilter;
    });
    const payload = filterPayloadFn('id_aircraft_registration', popupFilterState, intialStateFilter, dispatcher, filterThunk, qryParamsSetDecrypted, tableState);
    const payLoad = { ...payload };
    const isConcurrentToggle = columnLevelFilterState.concurrentToggle;
    payLoad.isConcurrentToggle = isConcurrentToggle;
    payLoad.isSbStatusToggle = tableState.sbStatusViewToggle;
    payLoad.limit = Number(getFromLocalStore('apiPaginationSize'));
    delete payLoad.columns;
    if ('isCheckerDetailsDataLoading' in columnLevelFilterState) {
      payLoad.columns = checkerDetailscolumns;
    }
    delete payLoad.orderBy;
    dispatcher(browserThunk(payLoad, payLoad.dataSource)());
  };

  const setOrderBy = (option: any) => {
    const opt = option === 'ASC' ? { option1: true, option2: false } : { option1: false, option2: true };
    setOptions(opt);
  };

  useEffect(() => {
    handleClose();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isDataSelectionModalOpen, browserState]);

  return (
    <div>
      <Container data-testid="popup-filter-modal">
        <Button size="small" variant="primary" className="data-filter-button" onClick={handleOpen} icon={<Tune />} data-testid="filter-button">
          {FILTER}
          {stateCount !== 0 && <Badge className="filter-count" count={stateCount} aria-label="" />}
        </Button>
        <Modal id="column-filter-id" className="data-filter-popup-container" aria-labelledby="modal-title" aria-describedby="modal-description" open={showFilterPanel}>
          <div className="filter-container">
            <Container className="parent-model-class">
              <Row className="filterSort-header-label">
                <Col xxs={8} className="filer-sort-header-title">
                  <Inline spacing="1-x">
                    <Tune className="filter-icon-color" />
                    <Typography variant="medium">{DATA_FILTER_TITLE}</Typography>
                  </Inline>
                </Col>
                <Col xxs={3}>
                  <Close className="modal-close-icon" onClick={handleClose} data-testid="modal-close" />
                </Col>
              </Row>
              <Row>
                {showNoData && <Typography variant="medium" className="nodata-label">{NODATA_FILTER}</Typography>}
              </Row>
              <Row className="tilte-header-filterby">
                <Col xxs={3}>
                  <Typography variant="small" className="filterby-header-label">
                    {FILTER_BY}
                  </Typography>
                </Col>
              </Row>
              <ComboboxUtils tableHeaders={tableHeaders} filterStates={popupFilterState} stateChange={getDataPayloadFn} setDataConfigFn={setDataConfig} resetForm={resetForm} tableState={tableState} dispatcher={dispatcher} filterthunk={filterThunk} filterPayloadFn={filterPayloadFn} reasonStatusMappingState={reasonStatusMap} />
              <Row className="tilte-header-sortby">
                <Col xxs={3}>
                  <Typography variant="small" className="dynamic-header">
                    {SORT_BY}
                  </Typography>
                </Col>
                <Col className="dynamic-header-outside">
                  <ComboBox
                    aria-label="Sort By"
                    size="small"
                    placeholder={COMBOBOX_PLACEHOLDER}
                    options={getSortByOptions(tableHeaders)}
                    value={selectSortByValue}
                    onChange={(value: any) => handleSortBySelection(value)}
                    hideClear
                    data-testid="table-column-test-id"
                  />
                </Col>
                <Col className="sort-by">
                  <Inline spacing="4-x">
                    <Radio label="Ascending" checked={options.option1} onChange={() => setOrderBy('ASC')} data-testid="ascending-radio-button" />
                    <Radio label="Descending" checked={options.option2} onChange={() => setOrderBy('DESC')} data-testid="descending-radio-button" />
                  </Inline>
                </Col>
              </Row>
              <Inline spacing="1-x" align="end" className="clear-apply-button">
                <Button size="small" disabled={!btnState} variant="secondary" data-testid="modal-reset" onClick={handleClearFilter}>
                  {CLEAR}
                </Button>
                <Button
                  size="small"
                  disabled={!btnState}
                  className="apply-button"
                  variant="primary"
                  onClick={() => {
                    if (selectSortByValue) {
                      const { payload, state } = dataFilterConfig;
                      payload.orderBy = new Array(selectSortByValue?.value);
                      payload.userOrderBy = true;
                      payload.sortType = options.option1 ? 'ASC' : 'DESC';
                      setDataConfig(payload, state);
                    }
                    const columnFilterStateMethods = { columnLevelFilterState, updateColumnFilterState, updateColumnFilterPayload };
                    handleApplyFilter({ setShowNoData }, columnFilterStateMethods, dataFilterConfig, dispatcher, browserThunk, reasonStatusMap);
                  }}
                  data-testid="modal-apply"
                >
                  {APPLY}
                </Button>
              </Inline>
            </Container>
          </div>
        </Modal>
      </Container>
    </div>
  );
};
export default ColumnLevelModal;
